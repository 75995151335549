import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from '../components/Layout/Layout';
import SEO from "../components/seo";
import Header from '../components/Header/Header-2';

const getdata = graphql`
{
  wpgraphql {
    page(id: "cG9zdDo3Nzcw") {
      id
      uri
      title
      content
      seo {
        canonical
        metaDesc
        metaKeywords
        focuskw
        title
        twitterTitle
        twitterDescription
        opengraphDescription
        opengraphPublishedTime
        opengraphModifiedTime
        opengraphTitle
        opengraphType
        opengraphImage {
          sourceUrl
        }
      }
    }
  }
}
  `
const Press = () => {
    const data = useStaticQuery(getdata);
    const common = data.wpgraphql.page;
    return (
        <>
            <Layout>
                <SEO title="Press" description={common.seo.metaDesc} canonical={common.uri} seo={common.seo} />
                <Header home={false}  menu='about'/>
                <main>
                  <section class="press-banner-title py-5">
                      <div class="container">
                          <h2 class="page-title text-white">Press</h2>
                      </div>
                  </section>
                  <section className="py-5">
                    <div className="container">
                        <div dangerouslySetInnerHTML={{ __html: common.content }} />
                    </div>

                </section >
                </main>

            </Layout >
        </>
    )
}
export default Press